import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Resource } from "@utils/globalTypes"
import { Card } from "react-bootstrap"
import { Link } from "gatsby"
import * as s from "./Resources.module.scss"

type Props = {
  resources: Resource[]
  title: string
}

const ResourcesContainer: React.FC<Props> = ({ resources, title }) => {
  return (
    <div className={s.container}>
      <h2 className={s.title}>{title}</h2>
      <Container className={s.guidesContainer}>
        <Row className={s.row}>
          {resources.map((resource, index) => (
            <Col className="mb-4" lg={3} md={6} xs={6} key={index}>
              <Link className="no-underline" to={`${resource?.slug?.current}`}>
                <Card className={s.card}>
                  <Card.Body className={s.body}>
                    <p className={s.cardTitle}>{resource.title}</p>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default ResourcesContainer
