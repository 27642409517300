import { AllGraphQLResponse, Resource } from "@utils/globalTypes"
import React, { FC } from "react"
import ResourcesContainer from "./Resources/index"

import SEO from "@components/common/SEO"
import { graphql } from "gatsby"

type Props = {
  data: {
    allSanityResourcePage: AllGraphQLResponse<Resource>
  }
  location: Location
}

const ResourcePage: FC<Props> = ({
  data: { allSanityResourcePage },
  location,
}) => {
  const metadata = {
    location: location.href,
  }
  const resources = allSanityResourcePage.edges.map((edge) => edge.node)

  return (
    <>
      <SEO {...metadata} />
      {resources?.length > 0 && (
        <ResourcesContainer
          resources={resources}
          title={`${resources[0].category.name} Categories`}
        />
      )}
    </>
  )
}

export default ResourcePage

export const query = graphql`
  query allSanityResourcePage($handle: String!) {
    allSanityResourcePage(
      filter: { category: { slug: { current: { eq: $handle } } } }
    ) {
      edges {
        node {
          slug {
            current
          }
          title
          category {
            name
          }
        }
      }
    }
  }
`
